import React, { Component } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

import './App.css';
import './styles/tailwind.css';

import { ScrollToTop } from './elements';
import { ContentProvider } from './providers';
import { config } from './config';

import { Header, Footer } from './layout';
import {
  Alumni,
  Application,
  Category,
  Checkout,
  Classes,
  Contact,
  Calendar,
  Details,
  JobPost,
  FAQ,
  Gift,
  Home,
  Page,
  Search,
  Staff,
  Winelist,
  TableTalk,
  TableTalkPost,
  Tour,
  Member,
  PrivateEvents,
} from './pages';

class App extends Component {
  constructor(props) {
    super(props);

    const history = createHistory();

    this.state = {
      history: history,
    };

    ReactGA.initialize(config.analyticsId_ga4);

    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: window.document.title,
    });

    const tagManagerArgs = {
      gtmId: 'GTM-KRRPXTLP',
    };

    TagManager.initialize(tagManagerArgs);
  }

  render() {
    return (
      <Router history={this.state.history}>
        <ContentProvider>
          <ScrollToTop>
            <main className='app'>
              <Header />
              <section className='page'>
                <Switch>
                  {/* Redirects */}
                  <Redirect
                    exact
                    from='/restaurant-management-program/'
                    to='/hospitality-management-program/'
                  />
                  <Redirect
                    exact
                    from='/restaurant-management-program/admissions/'
                    to='/hospitality-management-program/admissions/'
                  />
                  <Redirect
                    exact
                    from='/restaurant-management-program/complete-an-application/'
                    to='/hospitality-management-program/complete-an-application/'
                  />

                  <Route exact path='/' component={Home} />
                  <Route exact path='/about-us/' component={Page} />
                  <Route exact path='/about-us/staff/' component={Staff} />
                  <Route exact path='/contact-us/' component={Contact} />
                  <Route
                    exact
                    path='/culinary-arts-programs/'
                    component={Page}
                  />
                  <Route
                    exact
                    path='/culinary-arts-programs/admissions/'
                    component={Page}
                  />
                  <Route
                    exact
                    path='/culinary-arts-programs/complete-an-application/'
                    component={Application}
                  />
                  <Route
                    exact
                    path='/culinary-arts-programs/complete-an-application/thankyou/'
                    component={Page}
                  />
                  <Route
                    exact
                    path='/hospitality-management-program/'
                    component={Page}
                  />
                  <Route
                    exact
                    path='/hospitality-management-program/admissions/'
                    component={Page}
                  />
                  <Route
                    exact
                    path='/hospitality-management-program/complete-an-application/'
                    component={Application}
                  />
                  <Route
                    exact
                    path='/hospitality-management-program/complete-an-application/thankyou/'
                    component={Page}
                  />
                  <Route exact path='/table-talk/' component={TableTalk} />
                  <Route
                    exact
                    path='/table-talk/:slug/'
                    component={TableTalkPost}
                  />
                  <Route exact path='/faq/' component={FAQ} />
                  <Route exact path='/winelist/' component={Winelist} />
                  <Route
                    exact
                    path='/private-events/'
                    component={PrivateEvents}
                  />
                  <Route exact path='/classes/' component={Classes} />
                  <Route
                    exact
                    path='/product-category/:slug/'
                    component={Category}
                  />
                  <Route exact path='/search/' component={Search} />
                  <Route exact path='/product/:slug/' component={Details} />
                  <Route exact path='/calendar/' component={Calendar} />
                  <Route
                    exact
                    path='/cook-street-gift-cards/'
                    component={Gift}
                  />
                  {/* <Route
                    exact
                    path='/cook-street-membership/'
                    component={Member}
                  /> */}
                  <Route
                    exact
                    path='/cook-street-membership/'
                    component={Member}
                  />
                  <Route
                    exact
                    path='/cook-street-gift-cards/:slug/'
                    component={Gift}
                  />
                  <Route exact path='/checkout/' component={Checkout} />
                  <Route exact path='/alumni/' component={Alumni} />
                  <Route exact path='/alumni/post-a-job/' component={JobPost} />
                  <Route exact path='/3dtour/' component={Tour} />
                </Switch>
              </section>
              <Footer />
            </main>
          </ScrollToTop>
        </ContentProvider>
      </Router>
    );
  }
}

export default App;
